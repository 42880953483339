<script>
  import { onMount } from 'svelte';

  export let will_be = '';
  export let no_wave = false;

  let block = false;

  /** @type {HTMLElement} */
  let wave;
  let window_innerHeight;
  let fragmentHeight;
  let position = '0';

  function throttle(func, timeFrame) {
    var lastTime = 0;
    return function () {
      var now = Date.now();
      if (!block && now - lastTime >= timeFrame) {
        func();
        lastTime = now;
      }
    };
  }

  function scroll() {
    try {
      const scrollTop = document.documentElement.scrollTop + document.body.scrollTop;
      // const scrollTop = scrollY;
      const el_offsetTop = wave.offsetTop;
      const el_clientHeight = wave.clientHeight;
      const min = Math.max(0, el_offsetTop - window_innerHeight);
      const max = el_offsetTop + el_clientHeight;
      const scroll = scrollTop < min ? 0 : scrollTop >= max ? 1 : (scrollTop - min) / max;
      const percentage = Math.round((scroll + Number.EPSILON) * 10000) / 100;
      position = `${percentage}%`;
    } catch (_) {
      /* Figure out later but error here would block scrolling in mobile Safari */
    }
  }

  const scroll_perf = throttle(scroll, 100);

  onMount(() => scroll());
</script>

<svelte:window on:scroll={scroll_perf} bind:innerHeight={window_innerHeight} />

{#if will_be == 'none'}
  <slot />
{:else if will_be == 'pink'}
  <section>
    <div class="inner">
      <slot />
    </div>
  </section>
{:else}
  <section
    class:show-wave={!no_wave}
    class:above={will_be == 'above'}
    class:below={will_be == 'below'}
  >
    {#if fragmentHeight > 0}
      {#if will_be == 'above'}<div class="wave" bind:this={wave} style="--position: {position};" />{/if}
    {/if}
    <div class="inner" bind:clientHeight={fragmentHeight}>
      <slot />
    </div>
    {#if fragmentHeight > 0}
      {#if will_be == 'below'}<div class="wave" bind:this={wave} style="--position: {position};" />{/if}
    {/if}
  </section>
{/if}

<style lang="scss">
  .inner {
      /* min-height: 240px; */
      background-color: #fff5f1;
    }
    @media screen and (max-width: 425px) {
      .inner {
          overflow-wrap: break-word;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
        }
    } 
  .show-wave {
    z-index: 1;   
    &.above .wave,
    &.below .wave {
      display: block;
      position: relative;
      padding-top: 24%;
      background-image: url('/theme/mylly2023/assets/wave2.svg');
      background-size: 200%;
      background-repeat: no-repeat;
      transition: background-position-x 0.25s;
      background-position-x: var(--position);

      z-index: 0;

      /* b {
        position: absolute;
        color: red;
        top: 0;
        background-color: rgba(255,255,255,.5);
        white-space: pre;
      } */
    }

    &.navigating .wave {
      transition: none;
    }

    &.above .wave {
      transform: rotateZ(180deg);
      top: 1px;
      margin-top: calc(90vw * -0.24);
    }

    &.below .wave {
      display: block;
      top: -1px;
      margin-bottom: calc(90vw * -0.24);
      margin-left: -15px;
    }
  }

  .show-wave.below + :global(*) {
    position: relative;
    z-index: 5;
  }
</style>
